@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

.App-header {
  background-color: black;
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Logo-showys {
  display: flex;
  padding-left: 15px;
  width: 72%;
  transform: rotate(-6deg);
}

.Logo-kara-live-show {
  width: 85%;
  padding: 15px 10px 15px 15px;
}

.App-main {
  display: flex;
  justify-content: center;
  background-color: black;
  color: white;
}

@media only screen and (min-width: 650px) {
  .Container-logo-kara {
    display: flex;
  }

  .Logo-kara-live-show {
    width: 60%;
  }

  .Container-logo-showys {
    display: flex;
  }

  .Logo-showys {
    width: 55%;
    transform: rotate(0deg);
  }
}
